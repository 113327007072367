import React from "react"
import { PageProps } from "gatsby"
import { Layout } from "src/layout"
import { Calculator, LoadableFooter } from "src/components/Calculator"


function QuotationPage({ location }: PageProps) {
    return (
        <Layout location={location}>
            <Calculator />
            <LoadableFooter />
        </Layout>
    )
}

export default QuotationPage